export const deepCopy = src => {
  let target = Array.isArray(src) ? [] : {};
  for (let key in src) {
    let v = src[key];
    if (v) {
      if (typeof v === "object") {
        target[key] = deepCopy(v);
      } else {
        target[key] = v;
      }
    } else {
      target[key] = v;
    }
  }

  return target;
};

export const numberWithCommas = nStr => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let  rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
            x1 = x1.replace(rgx, `$1,$2`);
    }
    return x1 + x2;
};

export const copyBucketSettings = (segmentArray, tdBucket) => {
    let selectedBucketData = segmentArray.tdBuckets.filter(data => {
        return parseInt(data.type) === tdBucket;
    });
    let previousBucketData = segmentArray.tdBuckets.filter(data => {
        return parseInt(data.type) === tdBucket - 1;
    });

    //selectedBucketData[0].selectAllSize = parseInt(previousBucketData[0].selectAllSize) + 2;
    selectedBucketData[0].dealerPassedThreshold = previousBucketData[0].dealerPassedThreshold;
    selectedBucketData[0].dealerPassedThresholdTime = previousBucketData[0].dealerPassedThresholdTime;
    selectedBucketData[0].dlrsAxedOtherSide = previousBucketData[0].dlrsAxedOtherSide;
    selectedBucketData[0].maxNoDealers = previousBucketData[0].maxNoDealers;
    selectedBucketData[0].inventorySizeZeroMySide = previousBucketData[0].inventorySizeZeroMySide;
    selectedBucketData[0].excludeOpenTrading = previousBucketData[0].excludeOpenTrading;
    selectedBucketData[0].dealerSelectionPriorityList = deepCopy(previousBucketData[0].dealerSelectionPriorityList);
    selectedBucketData[0].copySettingFromPreviouseBucketEnabled = true;

}

export const resetBucketSettings = (oldSegmentArray, segmentArray, tdBucket) => {
    let selectedBucketData = segmentArray.tdBuckets.filter(data => {
        return parseInt(data.type) === tdBucket;
    });
    let copyFromObject = oldSegmentArray.tdBuckets.filter(data => {
        return parseInt(data.type) === tdBucket;
    });

    //selectedBucketData[0].selectAllSize = copyFromObject[0].selectAllSize;
    selectedBucketData[0].dealerPassedThreshold = copyFromObject[0].dealerPassedThreshold;
    selectedBucketData[0].dealerPassedThresholdTime = copyFromObject[0].dealerPassedThresholdTime;
    selectedBucketData[0].dlrsAxedOtherSide = copyFromObject[0].dlrsAxedOtherSide;
    selectedBucketData[0].maxNoDealers = copyFromObject[0].maxNoDealers;
    selectedBucketData[0].dealerSelectionPriorityList = deepCopy(copyFromObject[0].dealerSelectionPriorityList);
    selectedBucketData[0].copySettingFromPreviouseBucketEnabled = false;
    selectedBucketData[0].inventorySizeZeroMySide = copyFromObject[0].inventorySizeZeroMySide;
    selectedBucketData[0].excludeOpenTrading = copyFromObject[0].excludeOpenTrading;
}

export const addPropertyToObjects = (objects, key, value) => {
        if(objects !== undefined){
            objects.map((member) => {
                return {
                    ...member,
                    key: value
                }
            });
        }
}