export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const UPDATE_ENVIRONMENT = 'UPDATE_ENVIRONMENT';
export const SET_HAS_SELECTION = 'SET_HAS_SELECTION';
export const REMOVE_SELECTION_PRIORITY_ROW = 'REMOVE_SELECTION_PRIORITY_ROW';
export const SELECTION_PRIORITY_VALUE_CHANGE = 'SELECTION_PRIORITY_VALUE_CHANGE';
export const SELECTION_PRIORITY_VALUE_CHANGE_PREVIOUS = 'SELECTION_PRIORITY_VALUE_CHANGE_PREVIOUS';
export const SELECTION_PRIORITY_QUANTITY_CHANGE = 'SELECTION_PRIORITY_QUANTITY_CHANGE';
export const INCLUDE_TOP_DEALERS_VALUE_CHANGE = 'INCLUDE_TOP_DEALERS_VALUE_CHANGE';
export const TOP_DEALERS_AXED_ON_OTHER_SIDE_ALWAYS_CHANGE = 'TOP_DEALERS_AXED_ON_OTHER_SIDE_ALWAYS_CHANGE';
export const TOP_DEALERS_AXED_ON_OTHER_SIDE_NEVER_CHANGE = 'TOP_DEALERS_AXED_ON_OTHER_SIDE_NEVER_CHANGE';
export const TD_INVENTORY_SIZE_ZERO_MY_SIDE_CHANGE = 'TD_INVENTORY_SIZE_ZERO_MY_SIDE_CHANGE';
export const OPEN_TRADING_EXCLUSION_CHANGE = 'OPEN_TRADING_EXCLUSION_CHANGE';
export const GTA_AXED_ON_OTHER_SIDE_ALWAYS_CHANGE = 'GTA_AXED_ON_OTHER_SIDE_ALWAYS_CHANGE';
export const GTA_INVENTORY_SIZE_ZERO_MY_SIDE_CHANGE = 'GTA_INVENTORY_SIZE_ZERO_MY_SIDE_CHANGE';
export const GTA_AXED_ON_OTHER_SIDE_NEVER_CHANGE = 'GTA_AXED_ON_OTHER_SIDE_NEVER_CHANGE';
export const TOP_DEALERS_PASSED_IN_LAST = 'TOP_DEALERS_PASSED_IN_LAST';
export const GTA_PASSED_IN_LAST = 'GTA_PASSED_IN_LAST';
export const GTA_TIMINGS_CHANGED = 'GTA_TIMINGS_CHANGED';
export const TIMINGS_CHANGED = 'TIMINGS_CHANGED';
export const DRAG_OVER_PRIORITY = 'DRAG_OVER_PRIORITY';
export const SET_DRAG_DATA = 'SET_DRAG_DATA';
export const DRAG_END_PRIORITY = 'DRAG_END_PRIORITY';
export const SET_NEW_PRIORITY_LOCATION = 'SET_NEW_PRIORITY_LOCATION';
export const SET_PRIORITY_TO_BE_REMOVED = 'SET_PRIORITY_TO_BE_REMOVED';
export const ADD_NEW_PRIORITY = 'ADD_NEW_PRIORITY';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const ISIN_ISSUERS_CHANGE_INCLUDES = 'ISIN_ISSUERS_CHANGE_INCLUDES';
export const ISIN_DEALERS_CHANGE_INCLUDES = 'ISIN_DEALERS_CHANGE_INCLUDES';
export const ISIN_ISSUERS_CHANGE_EXCLUDES = 'ISIN_ISSUERS_CHANGE_EXCLUDES';
export const ISIN_DEALERS_CHANGE_EXCLUDES = 'ISIN_DEALERS_CHANGE_EXCLUDES';
export const RESPONSE_RATE_VALUE_CHANGED = 'RESPONSE_RATE_VALUE_CHANGED';
export const GO_TO_CHANGED = 'GO_TO_CHANGED';
export const SEGMENT_CHANGED = 'SEGMENT_CHANGED';
export const RESPONSE_RATE_SLIDE_CHANGED = 'RESPONSE_RATE_SLIDE_CHANGED';
export const BUCKET_ENABLE_SLIDE_CHANGED = 'BUCKET_ENABLE_SLIDE_CHANGED';
export const RESET_CURRENT_SEGMENT = 'RESET_CURRENT_SEGMENT';
export const FLIP_DUPLICATE_SMART_SELECT_DATA = 'FLIP_DUPLICATE_SMART_SELECT_DATA';
export const HISTORICAL_CHANGE = 'HISTORICAL_CHANGE';
export const SELECT_ALL_SIZE_CHANGE = 'SELECT_ALL_SIZE_CHANGE';
export const BUCKET_ONE_SIZE_CHANGE = 'BUCKET_ONE_SIZE_CHANGE';
export const BUCKET_TWO_SIZE_CHANGE = 'BUCKET_TWO_SIZE_CHANGE';
export const BUCKET_THREE_SIZE_CHANGE = 'BUCKET_THREE_SIZE_CHANGE';
export const BUCKET_FOUR_SIZE_CHANGE = 'BUCKET_FOUR_SIZE_CHANGE';
export const BUCKET_SIZE_RESET = 'BUCKET_SIZE_RESET';
export const HISTORIC_OPTION_CHANGE = 'HISTORIC_OPTION_CHANGE';
export const BUCKET_OPTION_CHANGE = 'BUCKET_OPTION_CHANGE';
export const CHECK_ERROR = 'CHECK_ERROR';
export const SAVE_SELECTION = 'SAVE_SELECTION';
export const UPDATE_COPY_SETTINGS_CHECKBOX_STATE = 'UPDATE_COPY_SETTINGS_CHECKBOX_STATE';
export const COPY_ITEMS_TO = 'COPY_ITEMS_TO';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_SAVED_MODAL = 'CLOSE_SAVED_MODAL';
export const ALERT_SAVED = 'ALERT_SAVED';
export const COPY_SETTINGS_FROM_PREVIOUS_BUCKET = 'COPY_SETTINGS_FROM_PREVIOUS_BUCKET';