import * as actionTypes from "../actions/actions";
import { deepCopy, copyBucketSettings, resetBucketSettings, addPropertyToObjects } from "../../utils/utils";
import {OPEN_TRADING_EXCLUSION_CHANGE} from "../actions/actions";

const initialStateServerData = window.userPreferencesStr;

const initialState = JSON.parse(
  JSON.stringify(initialStateServerData).replace("&#8216;", "'")
);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ENVIRONMENT: {
      let selectedSegmentArray =
        state.smartSelectData && state.smartSelectData.length
          ? state.smartSelectData.filter(selectionListItem => {
              return selectionListItem.selected;
            })
          : [{}];
      //addPropertyToObjects(selectedSegmentArray[0].tdBuckets, "copySettingFromPreviouseBucketEnabled", false);
      selectedSegmentArray[0].tdBuckets = selectedSegmentArray[0].tdBuckets.map(e => {
           return {
                ...e,
                copySettingFromPreviouseBucketEnabled : false
              }});
      return {
        ...state,
        copyToList: [],
        spinnerActive: false,
        duplicateSmartSelectData:
          state.smartSelectData && state.smartSelectData.length
            ? deepCopy(state.smartSelectData)
            : [],
        selectedSegment: selectedSegmentArray[0].marketSegment
      };
    }
    case actionTypes.SET_HAS_SELECTION: {
      let addToSearchArray = [];
      let tdAddToSearchArray = [];
      let tdBucketCount = 4;
      let selectedSegmentArray =
        state.smartSelectData && state.smartSelectData.length
          ? state.smartSelectData.filter(selectionListItem => {
              return selectionListItem.selected;
            })
          : [{}];

      if (state.options) {
    	for (
    	  let tdBucket = 1;
    	  tdBucket <= tdBucketCount;
    	  tdBucket++
    	) {
    	addToSearchArray = [];
        for (
          let i = 0;
          i < state.options.dealerSelectionsOptionsList.length;
          i++
        ) {
          let searchedValue =
            state.options.dealerSelectionsOptionsList[i].value;
          let tdBuckets = selectedSegmentArray[0].tdBuckets.filter(e => {
                return parseInt(e.type) === tdBucket;
          });
          if (
            tdBuckets[0] !== null && tdBuckets[0] !== undefined &&
            tdBuckets[0].dealerSelectionPriorityList !== null &&
            tdBuckets[0].dealerSelectionPriorityList.filter(e => {
              return e.value === searchedValue;
            }).length === 0
          ) {
            addToSearchArray = [
              ...addToSearchArray,
              state.options.dealerSelectionsOptionsList[i]
            ];
          }
        }
        tdAddToSearchArray[tdBucket] = addToSearchArray;
    	}
      }
      if(state.selectedSegment === "EM Hard Ccy" || state.selectedSegment === "Non-Core Ccys"){
    	  for (let i = 1;i < tdAddToSearchArray.length; i++){
    		  let subArr=tdAddToSearchArray[i];
    		  for (let j = 0;j < subArr.length; j++){
    			  if(subArr[j].id=="TRAX_ACTIVE_DEALERS"){
    				  subArr.splice(j,1);
    			  }
    		  }
    	  }
      }

      return {
        ...state,
        selectableOptions: tdAddToSearchArray
      };
    }
    case actionTypes.SEGMENT_CHANGED: {
        state.smartSelectData.map((data, index) => {
            if(data.marketSegment === action.payload.value){
                data.selected = true;
                state.selectedSegment = data.marketSegment;
            } else {
                data.selected = false;
            }
        });

        return {
            ...state,
            smartSelectData : state.smartSelectData
        };
    }
    case actionTypes.HISTORIC_OPTION_CHANGE: {
      return {
        ...state,
        historicPerformanceOption: action.payload.value
      };
    }

    case actionTypes.BUCKET_OPTION_CHANGE: {
    	return {
          ...state,
          tdBucket: action.payload.value
        };
     }

    case actionTypes.SELECT_ALL_SIZE_CHANGE: {
    	let selectedSegmentArray = state.smartSelectData.filter(data => {
            return data.selected;
        });

        selectedSegmentArray[0].tdBuckets.map(e => {
          if(parseInt(e.type) === action.payload.tdBucket){
              if(isNaN(action.payload.value) || action.payload.value === null || action.payload.value === "" || action.payload.value < 0){
                e.selectAllSize = "";
              } else if(parseInt(e.type) === 1 && action.payload.value > 999998){
                e.selectAllSize = e.selectAllSize;
              } else if(parseInt(e.type) !== 1 && action.payload.value > 999999){
                e.selectAllSize = e.selectAllSize;
              } else if(parseInt(e.type) === 1){
                selectedSegmentArray[0].selectAllSize = action.payload.value;
                e.selectAllSize = action.payload.value;
              } else {
                e.selectAllSize = action.payload.value;
              }
          }
        });
        return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data, tdBuckets: selectedSegmentArray[0].tdBuckets}
              : { ...data, tdBuckets: data.tdBuckets}
          )
        };
  }

    case actionTypes.BUCKET_SIZE_RESET: {
    	let selectedSegmentArray = state.smartSelectData.filter(data => {
            return data.selected;
          });

      selectedSegmentArray[0].tdBuckets.map((data, index) => {
            if(data.active && parseInt(data.type) !== 1 && data.selectAllSize != null && (parseInt(data.selectAllSize) - 2) <= parseInt(selectedSegmentArray[0].tdBuckets[index - 1].selectAllSize)) {
                data.selectAllSize = parseInt(data.type) !== 2 ? parseInt(selectedSegmentArray[0].tdBuckets[index - 1].selectAllSize) + 2 : parseInt(selectedSegmentArray[0].tdBuckets[index - 1].selectAllSize) + 3;
            } else {
            }
        });

        return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data, tdBuckets: selectedSegmentArray[0].tdBuckets}
              : { ...data, tdBuckets: data.tdBuckets}
          )
        };
    }
    case actionTypes.FLIP_DUPLICATE_SMART_SELECT_DATA: {
      return {
        ...state,
        duplicateSmartSelectData: state.duplicateSmartSelectData.map(
          (data, index) =>
            data.marketSegment === action.payload.value
              ? {
                  ...data,
                  selected: true
                }
              : {
                  ...data,
                  selected: false
                }
        )
      };
    }
    case actionTypes.DRAG_OVER_PRIORITY: {
      action.payload.value.persist();
      action.payload.value.preventDefault();
      return {
        ...state,
        draggedOver: action.payload.value.target.innerText
      };
    }
    case actionTypes.SET_DRAG_DATA: {
      action.payload.value.dataTransfer.setData(
        "text",
        action.payload.value.target.innerText
      );
      let segmentArray = state.smartSelectData.filter(data => {
          return data.selected;
        });
      let tdBuckets = segmentArray[0].tdBuckets.filter(e => {
                  return parseInt(e.type) === action.payload.tdBucket;
            });
      let dragIndex; 
      tdBuckets[0].dealerSelectionPriorityList.map((item, index) => {
    	  if(item.priority === action.payload.changeIndex){
    		  dragIndex = index;
    	  }
    	  return (item = { ...item, priority: item.priority });
      });
      return {
        ...state,
        dragStart: dragIndex
      };
    }

    case actionTypes.SET_PRIORITY_TO_BE_REMOVED: {
      action.payload.value.persist();
      let segmentArray = state.smartSelectData.filter(data => {
        return data.selected;
      });
      let tdBuckets = segmentArray[0].tdBuckets.filter(e => {
          return parseInt(e.type) === action.payload.tdBucket;
        });
      let movedData =
        tdBuckets[0].dealerSelectionPriorityList[state.dragStart]
      state = { ...state, movedData };

      let dealerSelectionPriorityList = [
        ...tdBuckets[0].dealerSelectionPriorityList
      ].filter(item => {
        return item.value !== state.movedData.value;
      });
      segmentArray[0].tdBuckets.filter(e => {
        if(parseInt(e.type) === action.payload.tdBucket){
            e.dealerSelectionPriorityList = dealerSelectionPriorityList;
        }
      });
      return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data, tdBuckets: segmentArray[0].tdBuckets}
              : { ...data, tdBuckets: data.tdBuckets}
          )
        };
    }
    case actionTypes.SET_NEW_PRIORITY_LOCATION: {
      action.payload.value.persist();
      let segmentArray = state.smartSelectData.filter(data => {
        return data.selected;
      });
      let tdBuckets = segmentArray[0].tdBuckets.filter(e => {
          return parseInt(e.type) === action.payload.tdBucket;
      });
      let itemIndex;

      for (
        let i = 0;
        i < tdBuckets[0].dealerSelectionPriorityList.length;
        i++
      ) {
        if (
          state.draggedOver ===
          tdBuckets[0].dealerSelectionPriorityList[i].value
        ) {
          itemIndex = i;
        }
      }

      let tempArray = [...tdBuckets[0].dealerSelectionPriorityList];
      if (state.dragStart === 1 && itemIndex === 0) {
        tempArray.splice(itemIndex, 0, state.movedData);
      } else if (
        state.dragStart > itemIndex &&
        state.dragStart - itemIndex === 1
      ) {
        tempArray.splice(itemIndex, 0, state.movedData);
      } else if (state.dragStart > itemIndex) {
        tempArray.splice(itemIndex, 0, state.movedData);
      } else if (state.dragStart === itemIndex) {
        tempArray.splice(itemIndex + 1, 0, state.movedData);
      } else {
        tempArray.splice(itemIndex + 1, 0, state.movedData);
      }

      let tdBucketIndexStart = 0;
      
      let reOrderedArray = tempArray.map((item, index) => {
        //if(item.priority > action.payload.arrayPriority)
          if(tdBucketIndexStart === 0)
              tdBucketIndexStart = index;
          if(item.tdBucket > 1)
              return (item = { ...item, priority: index + 1});
          else
              return (item = { ...item, priority: index + 1});
        return (item = { ...item, priority: item.priority });
      });

        segmentArray[0].tdBuckets.filter(e => {
        if(parseInt(e.type) === action.payload.tdBucket){
            e.dealerSelectionPriorityList = reOrderedArray;
        }
      });
      return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data, tdBuckets: segmentArray[0].tdBuckets}
              : { ...data, tdBuckets: data.tdBuckets}
          )
        };
    }
    case actionTypes.DRAG_END_PRIORITY: {
      action.payload.value.persist();
      return {
        ...state
      };
    }
    case actionTypes.ADD_NEW_PRIORITY: {
      let segmentArray = state.smartSelectData.filter(data => {
          return data.selected;
      });
      let selectedBucketData = segmentArray[0].tdBuckets.filter(data => {
          return parseInt(data.type) === action.payload.tdBucket;
      });
      let priorityId = selectedBucketData[0].dealerSelectionPriorityList.length + 1;

      let newPriorityObject = {
        id: priorityId,
        value: "",
        priority: priorityId,
        quantity: "",
        tdBucket: action.payload.tdBucket
      };

      if(selectedBucketData[0].dealerSelectionPriorityList.length < 6){
        selectedBucketData[0].dealerSelectionPriorityList[priorityId - 1] = newPriorityObject;
      }

      return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data, tdBuckets: segmentArray[0].tdBuckets}
              : { ...data, tdBuckets: data.tdBuckets}
          )
      };
  }

    case actionTypes.REMOVE_SELECTION_PRIORITY_ROW: {
      let segmentArray = state.smartSelectData.filter(data => {
          return data.selected;
      });
      let selectedBucketData = segmentArray[0].tdBuckets.filter(data => {
          return parseInt(data.type) === action.payload.tdBucket;
      });

      let removedItemArray = selectedBucketData[0].dealerSelectionPriorityList
        .slice(0)
        .filter(item => {
          return item.priority === action.payload.arrayPriority;
        });

      if (removedItemArray[0].groups) {
        state.options.dealerSelectionsOptionsList.map((item, index) => {
          if (item.value === removedItemArray[0].value) {
            delete state.options.dealerSelectionsOptionsList[index].groups;
            state.options.dealerGroups.map(item => {
              if (item.value === removedItemArray[0].value && item.tdBucket === action.payload.tdBucket) {
                for (let i = 0; i < item.groups.length; i++) {
                  delete item.groups[i].selected;
                }
              }
            });
          }
        });
      }

      let dealerSelectionPriorityListItem = selectedBucketData[0].dealerSelectionPriorityList.filter(
        item => {
          return (item.priority !== action.payload.arrayPriority);
        }
      );

      let reOrderedList = dealerSelectionPriorityListItem.slice();

      reOrderedList.map((item, index) => {
        if(item.priority > action.payload.arrayPriority)
            return (item.priority = item.priority -1);
    	return item.priority = item.priority;
      });

      segmentArray[0].tdBuckets.map(data => {
          if(parseInt(data.type) === action.payload.tdBucket){
            data.dealerSelectionPriorityList = reOrderedList;
          }
      });
      return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data, tdBuckets: segmentArray[0].tdBuckets}
              : { ...data, tdBuckets: data.tdBuckets}
          )
      };
    }

    case actionTypes.SELECTION_PRIORITY_VALUE_CHANGE: {
      let valueIndex;
      let segmentArray = state.smartSelectData.filter(data => {
        return data.selected;
      });
      let selectedBucketData = segmentArray[0].tdBuckets.filter(data => {
          return parseInt(data.type) === action.payload.tdBucket;
      });
      if (
        selectedBucketData[0].dealerSelectionPriorityList[
          action.payload.changeIndex - 1
        ].value === action.payload.value &&
        !selectedBucketData[0].dealerSelectionPriorityList[
          action.payload.changeIndex - 1
        ].hasGroup && 
        selectedBucketData[0].dealerSelectionPriorityList[
            action.payload.changeIndex - 1
            ].tdBucket === action.payload.tdBucket
      ) {
        return state;
      }

      let searchedObject = state.options.dealerSelectionsOptionsList.filter(
        (item, index) => {
          valueIndex = index;
          return item.value === action.payload.value;
        }
      );

      if (searchedObject.length) {
        if (searchedObject[0].hasGroup) {
          let changedObject = selectedBucketData[0].dealerSelectionPriorityList.filter(data => {
              return data.priority === action.payload.changeIndex;
          });
          state.options.dealerSelectionsOptionsList[valueIndex].groups =
            state.options.dealerGroups;
          if(changedObject !== undefined){
              changedObject[0].id = searchedObject[0].id;
              changedObject[0].value = action.payload.value;
              changedObject[0].quantity = "";
              changedObject[0].groups = state.options.dealerGroups;
              changedObject[0].hasGroup = searchedObject[0].hasGroup;
              if (action.payload.group) {
                  changedObject[0].group = action.payload.group;
              }
          }
        } else {
        	selectedBucketData[0].dealerSelectionPriorityList.filter(data => {
                if(data.priority === action.payload.changeIndex){
                    delete data.group;
                    delete data.groups;
                    delete data.hasGroup;
                    data.id = searchedObject[0].id;
                    data.quantity = "";
                    data.value = action.payload.value;
                }
            });
        }
      }

      segmentArray[0].tdBuckets.filter(data => {
          if(parseInt(data.type) === action.payload.tdBucket) {
            data = selectedBucketData[0];
          }
      });
      return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data, tdBuckets: segmentArray[0].tdBuckets}
              : { ...data, tdBuckets: data.tdBuckets}
          )
      };
    }
    case actionTypes.UPDATE_GROUP: {
      let segmentArray = state.smartSelectData.filter(data => {
          return data.selected;
        });
        let selectedBucketData = segmentArray[0].tdBuckets.filter(data => {
            return parseInt(data.type) === action.payload.tdBucket;
        });

        selectedBucketData[0].dealerSelectionPriorityList.map(content =>
           content.value === action.payload.groupParent
             ? { ...content, group: action.payload.value }
             : content
         )
      return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data, tdBuckets: segmentArray[0].tdBuckets}
              : { ...data, tdBuckets: data.tdBuckets}
          )
      };
    }
    case actionTypes.SELECTION_PRIORITY_QUANTITY_CHANGE: {
        let segmentArray = state.smartSelectData.filter(data => {
            return data.selected;
        });
        let selectedBucketData = segmentArray[0].tdBuckets.map(data => {
            if(parseInt(data.type) === action.payload.tdBucket){
                data.dealerSelectionPriorityList.map(content => {
                    if(content.priority === action.payload.changeIndex){
                        content.quantity = action.payload.value;
                    }
                })
            }
        });

        return {
            ...state,
            smartSelectData: state.smartSelectData.map(data =>
                data.selected
                  ? { ...data, tdBuckets: segmentArray[0].tdBuckets}
                  : { ...data, tdBuckets: data.tdBuckets}
            )
        };
    }
    case actionTypes.INCLUDE_TOP_DEALERS_VALUE_CHANGE: {
        let segmentArray = state.smartSelectData.filter(data => {
            return data.selected;
        });
        segmentArray[0].tdBuckets.map(data => {
            if(parseInt(data.type) === action.payload.tdBucket){
                data.maxNoDealers = action.payload.value;
            }
        });
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? { ...data, tdBuckets: segmentArray[0].tdBuckets}
            : { ...data, tdBuckets: data.tdBuckets}
        )
      };
    }
    case actionTypes.RESPONSE_RATE_VALUE_CHANGED: {
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? { ...data, gtaResponseRateThreshold: action.payload.value }
            : {
                ...data,
                gtaResponseRateThreshold: data.gtaResponseRateThreshold
              }
        )
      };
    }
    case actionTypes.RESPONSE_RATE_SLIDE_CHANGED: {
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? { ...data, gtaResponseRateActive: action.payload.value }
            : { ...data, gtaResponseRateActive: data.gtaResponseRateActive }
        )
      };
    }
    case actionTypes.BUCKET_ENABLE_SLIDE_CHANGED: {

            let segmentArray = state.smartSelectData.filter(data => {
                    return data.selected;
                  });
            let selectedBucketData = segmentArray[0].tdBuckets.filter(data => {
                    return parseInt(data.type) === action.payload.tdBucket;
                  });
            let previousBucketData = segmentArray[0].tdBuckets.filter(data => {
                    return parseInt(data.type) === action.payload.tdBucket - 1;
                  });

            if(action.payload.tdBucket !== 1 && !action.payload.value){
                segmentArray[0].tdBuckets.map(data => {
                    if(parseInt(data.type) >= action.payload.tdBucket && data.active){
                        data.active = action.payload.value;
                        data.selectAllSize = null;
                    }
                });
            } else if(parseInt(previousBucketData[0].type) === 1 || previousBucketData[0].active){
                selectedBucketData[0].active = action.payload.value;
                selectedBucketData[0].selectAllSize = parseInt(previousBucketData[0].selectAllSize) + 2;
                 selectedBucketData[0].copySettingFromPreviouseBucketEnabled = action.payload.value;
                if(action.payload.tdBucket === 2){
                   selectedBucketData[0].selectAllSize = parseInt(previousBucketData[0].selectAllSize) + 3;
                }
                copyBucketSettings(segmentArray[0], action.payload.tdBucket);
            }

          return {
                  ...state,
                  smartSelectData: state.smartSelectData
            };
        }
    case actionTypes.GTA_AXED_ON_OTHER_SIDE_ALWAYS_CHANGE: {
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? {
                ...data,
                gtaAxedOtherSideOnly: action.payload.value
              }
            : {
                ...data,
                gtaAxedOtherSideOnly: data.gtaAxedOtherSideOnly
              }
        )
      };
    }

    case actionTypes.GTA_INVENTORY_SIZE_ZERO_MY_SIDE_CHANGE: {
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? {
                ...data,
                gtaInventorySizeZeroMySide: action.payload.value
              }
            : {
                ...data,
                gtaInventorySizeZeroMySide: data.gtaInventorySizeZeroMySide
              }
        )
      };
    }

    case actionTypes.GTA_AXED_ON_OTHER_SIDE_NEVER_CHANGE: {
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? {
                ...data,
                gtaNeverExclAxedYourSide: action.payload.value
              }
            : {
                ...data,
                gtaNeverExclAxedYourSide: data.gtaNeverExclAxedYourSide
              }
        )
      };
    }
    case actionTypes.TOP_DEALERS_AXED_ON_OTHER_SIDE_ALWAYS_CHANGE: {
        let segmentArray = state.smartSelectData.filter(data => {
            return data.selected;
        });
        segmentArray[0].tdBuckets.map(data => {
            if(parseInt(data.type) === action.payload.tdBucket){
                data.dlrsAxedOtherSide = action.payload.value;
            }
        });
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? {
                ...data,
                tdBuckets: segmentArray[0].tdBuckets
              }
            : {
                ...data,
                tdBuckets: data.tdBuckets
              }
        )
      };
    }

    case actionTypes.TD_INVENTORY_SIZE_ZERO_MY_SIDE_CHANGE: {
        let segmentArray = state.smartSelectData.filter(data => {
                return data.selected;
        });
        segmentArray[0].tdBuckets.map(data => {
            if(parseInt(data.type) === action.payload.tdBucket){
                data.inventorySizeZeroMySide = action.payload.value;
            }
        });
      return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? {
                  ...data,
                  tdBuckets: segmentArray[0].tdBuckets
                }
              : {
                  ...data,
                  tdBuckets: data.tdBuckets
                }
          )
        };
    }

      case actionTypes.OPEN_TRADING_EXCLUSION_CHANGE: {
          let segmentArray = state.smartSelectData.filter(data => {
              return data.selected;
          });
          segmentArray[0].tdBuckets.map(data => {
              if(parseInt(data.type) === action.payload.tdBucket){
                  data.excludeOpenTrading = action.payload.value;
              }
          });
          return {
              ...state,
              smartSelectData: state.smartSelectData.map(data =>
                  data.selected
                      ? {
                          ...data,
                          tdBuckets: segmentArray[0].tdBuckets
                      }
                      : {
                          ...data,
                          tdBuckets: data.tdBuckets
                      }
              )
          };
      }

    case actionTypes.GTA_PASSED_IN_LAST: {
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? { ...data, gtaPassedActive: action.payload.value }
            : { ...data, gtaPassedActive: data.gtaPassedActive }
        )
      };
    }

    case actionTypes.COPY_SETTINGS_FROM_PREVIOUS_BUCKET:
    {
        let segmentArray = state.smartSelectData.filter(data => {
            return data.selected;
        });
        if(action.payload.value){
            copyBucketSettings(segmentArray[0], action.payload.tdBucket);
        } else {
            let duplicateSmartSelectData = state.duplicateSmartSelectData.filter(data => {
               return data.marketSegment === segmentArray[0].marketSegment
            });
            resetBucketSettings(duplicateSmartSelectData[0], segmentArray[0], action.payload.tdBucket);
        }
        return {
              ...state,
              smartSelectData: state.smartSelectData
        };
    };

    case actionTypes.TOP_DEALERS_PASSED_IN_LAST: {
        let segmentArray = state.smartSelectData.filter(data => {
            return data.selected;
          });
        segmentArray[0].tdBuckets.map(data => {
              if(parseInt(data.type) === action.payload.tdBucket){
                    data.dealerPassedThreshold = action.payload.value;
              }
          });

      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? { ...data, 
        	  	tdBuckets: segmentArray[0].tdBuckets
        	  }
            : {
                ...data,
                tdBuckets: data.tdBuckets
              }
        )
      };
    }
    case actionTypes.GTA_TIMINGS_CHANGED: {
      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? { ...data, gtaPassedThreshold: action.payload.value }
            : {
                ...data,
                gtaPassedThreshold: data.gtaPassedThreshold
              }
        )
      };
    }
    case actionTypes.TIMINGS_CHANGED: {
            let segmentArray = state.smartSelectData.filter(data => {
                return data.selected;
              });
            segmentArray[0].tdBuckets.map(data => {
                  if(parseInt(data.type) === action.payload.tdBucket){
                        data.dealerPassedThresholdTime = action.payload.value;
                  }
              });

      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected
            ? { 
        	  	...data, 
        	  	tdBuckets: segmentArray[0].tdBuckets
        	  	}
            : {
                ...data,
                tdBuckets: data.tdBuckets
              }
        )
      };
    }
    case actionTypes.GO_TO_CHANGED: {
        let segmentArray = state.smartSelectData.filter(data => {
            return data.selected;
        });
        segmentArray[0].smartSelectType = action.payload.value;
        let selectedBucketData = segmentArray[0].tdBuckets.filter(data => {
            return parseInt(data.type) === 1;
        });

        if(action.payload.value === "Top Dealers"){
            selectedBucketData[0].selectAllSize = 0;
        } else if(action.payload.value === "Go To All & Top Dealers"){
            if(segmentArray[0].selectAllSize !== undefined && segmentArray[0].selectAllSize != null && parseInt(segmentArray[0].selectAllSize) !== 0){
                selectedBucketData[0].selectAllSize = segmentArray[0].selectAllSize;
            }
        }
      return {
          ...state,
          smartSelectData: state.smartSelectData.map(data =>
            data.selected
              ? { ...data,
                tdBuckets: segmentArray[0].tdBuckets
              }
              : {
                  ...data,
                  tdBuckets: data.tdBuckets
                }
          )
        };
    }
    case actionTypes.ISIN_ISSUERS_CHANGE_INCLUDES: {
      return {
        ...state,
        isinsIssuersIncludes: action.payload.value
      };
    }
    case actionTypes.ISIN_DEALERS_CHANGE_INCLUDES: {
      return {
        ...state,
        dlrsIncludes: action.payload.value
      };
    }
    case actionTypes.ISIN_ISSUERS_CHANGE_EXCLUDES: {
      return {
        ...state,
        isinsIssuersExcludes: action.payload.value
      };
    }
    case actionTypes.ISIN_DEALERS_CHANGE_EXCLUDES: {
      return {
        ...state,
        dlrsExcludes: action.payload.value
      };
    }
    case actionTypes.RESET_CURRENT_SEGMENT: {
      return {
        ...state,
        smartSelectData: state.smartSelectData.map((data, index) =>
          deepCopy(state.duplicateSmartSelectData[index])
        )
      };
    }
    case actionTypes.HISTORICAL_CHANGE: {
      let segmentArray = state.smartSelectData.filter(data => {
        return data.selected;
      });

      segmentArray[0].liquidity.map(item => {
        if (
          item.type.toLowerCase() ===
          state.historicPerformanceOption.toLowerCase()
        ) {
          item[action.payload.name] = action.payload.value;
        }
      });

      return {
        ...state,
        smartSelectData: state.smartSelectData.map(data =>
          data.selected ? { ...data, liquidity: data.liquidity } : data
        )
      };
    }

    case actionTypes.CHECK_ERROR: {
      let error = false;
      let saveError = false;

      let updateMarketSegment = (segment, isError) => {
        if (!segment) return;
        state.options.marketSegmentsList.map(sgmnt => {
          if (sgmnt.segment === segment) {
            if (isError) {
              sgmnt.isError = true;
            } else {
              delete sgmnt.isError;
            }
          }
        });
      };

      state.smartSelectData.map(data => {
       if(data.smartSelectType !== "Go To All"){
        data.tdBuckets.map((bucketData, index) => {
        if(index === 0 || bucketData.active){
            if(bucketData.active && !bucketData.maxNoDealers) {
              error = true;
              saveError = true;
            }
            if(bucketData.dealerPassedThreshold && !bucketData.dealerPassedThresholdTime) {
              error = true;
              saveError = true;
            }
            if(bucketData.maxNoDealers === "") {
              error = true;
              saveError = true;
            }
            if(parseInt(bucketData.type) === 1 && bucketData.selectAllSize === ""){
              error = true;
              saveError = true;
            }
            bucketData.dealerSelectionPriorityList.map(item => {
            if (
                !item.quantity ||
                !item.value ||
                (item.groups && item.groups.length && !item.group)
            ) {
                error = true;
                saveError = true;
            }
            })
        }
        if(bucketData.active){
            if(index < 4 && (bucketData.selectAllSize === "" || parseInt(bucketData.selectAllSize) - (index === 1?2:1)<= (parseInt(data.tdBuckets[index - 1].selectAllSize)))) {
              error = true;
              saveError = true;
            }
         }
        });
        }

        if(data.smartSelectType !== "Top Dealers"){
            if (data.gtaResponseRateActive && !data.gtaResponseRateThreshold) {
                error = true;
                saveError = true;
            }
            if (data.gtaPassedActive && !data.gtaPassedThreshold) {
                error = true;
                saveError = true;
            }
        }

        if (error) {
          updateMarketSegment(data.marketSegment, true);
          error = false;
        } else {
          updateMarketSegment(data.marketSegment, false);
        }
      });

      let issuerErrorExcludes =
        state.isinsIssuersExcludes.length > 0 &&
        state.dlrsExcludes.length === 0;

      if (issuerErrorExcludes) {
        saveError = true;
      }

      let issuerErrorIncludes =
         (state.dlrsIncludes.length === 0 &&
          state.isinsIssuersIncludes.length > 0);

      if (issuerErrorIncludes) {
        saveError = true;
      }

      return {
        ...state,
        error: saveError
      };
    }

    case actionTypes.UPDATE_ENVIRONMENT: {
      window.userPreferencesStr = state;
      return state;
    }

    case actionTypes.UPDATE_COPY_SETTINGS_CHECKBOX_STATE: {
      return {
        ...state,
        copyToList: action.payload.checked
          ? [...state.copyToList.concat(action.payload.segment)]
          : state.copyToList.filter(item => {
              return item !== action.payload.segment;
            })
      };
    }

    case actionTypes.OPEN_MODAL: {
      return {
        ...state,
        copyToList: [],
        modalIsOpen: true
      };
    }

    case actionTypes.CLOSE_MODAL: {
      return {
        ...state,
        copyToList: [],
        modalIsOpen: false
      };
    }

    case actionTypes.ALERT_SAVED: {
      return {
        ...state,
        savedAlertOpen: true,
        isSavedSuccess: action.payload.value
      };
    }

    case actionTypes.CLOSE_SAVED_MODAL: {
      return {
        ...state,
        savedAlertOpen: false,
        isSavedSuccess: ""
      };
    }

    case actionTypes.COPY_ITEMS_TO: {
      if (!state.copyToList.length) return state;

      const getSegmantData = segment => {
        let newSegmentObject = {
          ...itemObjectToCopy[0],
          marketSegment: segment
        };

        return deepCopy(newSegmentObject);
      };

      let itemObjectToCopy = state.smartSelectData.filter(item => {
        return item.marketSegment === state.selectedSegment;
      });

      return {
        ...state,
        smartSelectData: state.smartSelectData.map((data, index) =>
          state.copyToList.includes(data.marketSegment)
            ? (data = getSegmantData(data.marketSegment))
            : data
        )
      };
    }

    default:
      return state;
  }
};

export default reducer;
