import React from "react";
import PropTypes from "prop-types";
import DealerPriorityItem from "../dealer-priority-item/dealer-priority-item";
import DealerSelectionPriorityAction from "../../components/dealer-selection-priority-action//dealer-selection-priority-action";
import DealerExclusions from "../../components/dealer-exclusions/dealer-exclusions";
import DealerPriorityContainer from "../../components/dealer-priority-container/dealer-priority-container";
import { numberWithCommas } from "../../utils/utils";
import Switch from "react-switch";

const topDealer = props => {
const topDealersQuantityArray = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      "All"
    ];
    const selectedOption = props.bucketOptions.filter(item => {
        return (
          parseInt(item.type) === props.tdBucket
        );
    });

    const previouseBucketData = props.bucketOptions.filter(item => {
        return (
          props.tdBucket !== 1 && parseInt(item.type) === props.tdBucket-1
        );
    });
  return (
        <div
          className={`row ${
            props.smartSelectType.toLowerCase() === "go to all"
              ? "overlay"
              : ""
          }`}
        >
          <div className="col-xs-12 col-md-12 sides light-border-bottom pt-10">
            <span> </span>
            <span> </span>
            <label className={props.tdBucket != 1 && !selectedOption[0].active?"mid-opacity-input":""}>Include only my Top</label>
            <select
              disabled={props.tdBucket != 1 && !selectedOption[0].active}
              id="selectionTopDealersQty"
              className={`input-sm sides  ${
              selectedOption[0].maxNoDealers
                 === ""
                  ? "error"
                  : null
              }
              ${props.tdBucket != 1 && !selectedOption[0].active?"mid-opacity-input":""}
              `}
              value={selectedOption[0].maxNoDealers}
              onChange={evt =>
                props.includeTopDealers(evt.target.value,props.tdBucket)
              }
            >
              <option value="" />
              {topDealersQuantityArray.map((quantity, index) => {
                return (
                  <option key={index} value={quantity}>
                    {quantity}
                  </option>
                );
              })}
            </select>
            <label className={props.tdBucket != 1 && !selectedOption[0].active?"mid-opacity-input":""}>&nbsp;&nbsp;Dealers</label>
              {props.smartSelectType === "go to all & top dealers" || (props.smartSelectType === "top dealers" && props.tdBucket !== 1) ?
              (<span className="flex-bucket-size">
                <label
                 className={(props.tdBucket !== 1 && ((parseInt(previouseBucketData[0].type) === 1 && (previouseBucketData[0].selectAllSize === "" || previouseBucketData[0].selectAllSize === null)) || (previouseBucketData[0].type != 1 && (!previouseBucketData[0].active || previouseBucketData[0].selectAllSize === "" || previouseBucketData[0].selectAllSize === null))))?"font-norm mid-opacity-input":"font-norm"}
                >when size is >= (000s) </label>
                {props.tdBucket !== 1 ?
                (<Switch
                    className={"margin-sides"}
                    height={15}
                    width={30}
                    onChange={evt => props.bucketEnableSlideChanged(evt,props.tdBucket)}
                    checked={selectedOption[0].active}
                    onColor="#337ab7"
                    offColor="#a0a3a8"
                    disabled={(parseInt(previouseBucketData[0].type) === 1 && (previouseBucketData[0].selectAllSize === "" || previouseBucketData[0].selectAllSize === null)) || (previouseBucketData[0].type != 1 && (!previouseBucketData[0].active || previouseBucketData[0].selectAllSize === "" || previouseBucketData[0].selectAllSize === null))}
                />):""
                }
                <span> </span>
                <input
                    type="number"
                    name="fname"
                    className={`input-sm input-sm-bucket ${
                                props.tdBucket != 1 && !selectedOption[0].active
                                  ? "mid-opacity-input"
                                  : ""
                              }
                              ${(props.tdBucket === 1 || selectedOption[0].active) && (selectedOption[0].selectAllSize === "" || (previouseBucketData.length !== 0 && (parseInt(selectedOption[0].selectAllSize) - (props.tdBucket === 2?2:1)) <= parseInt(previouseBucketData[0].selectAllSize)))
                                  ? "error"
                                  : null}`}
                    disabled={props.tdBucket != 1 && !selectedOption[0].active?"disabled":""}
                    value={selectedOption[0].selectAllSize
                                                  === "" || selectedOption[0].selectAllSize === null ||
                                                  selectedOption[0].selectAllSize === undefined ? "" : parseInt(selectedOption[0].type) === 1 && props.smartSelectType !== "top dealers"? parseInt(selectedOption[0].selectAllSize) + 1 : selectedOption[0].selectAllSize}
                    onChange={event =>
                      props.bucketSizeChanged(parseInt(selectedOption[0].type) === 1 ? parseInt(event.target.value) - 1 : parseInt(event.target.value), props.tdBucket)
                    }
                    onBlur={selectedOption[0].active && props.tdBucket !== 1 && (parseInt(selectedOption[0].selectAllSize) - 2) <= parseInt(previouseBucketData[0].selectAllSize) ? null :event =>
                      props.bucketSizeReset()
                    }
                  />
              </span>):""}

          </div>
          <DealerPriorityContainer
            tdBucket={props.tdBucket}
            selectedOption={selectedOption[0]}
            dealerPriorityList={props.dealerPriorityList}
            selectableOptions={props.selectableOptions}
            removeSelectionPriorityRow={
              props.removeSelectionPriorityRow
            }
            selectionPriorityValueChange={
              props.selectionPriorityValueChange
            }
            selectionPriorityQuantityChange={
              props.selectionPriorityQuantityChange
            }
            dragOver={props.dragOver}
            drop={props.drop}
            setDragData={props.setDragData}
            dragEnd={props.dragEnd}
            dealerSelectionsOptionsListCount={
              props.dealerSelectionsOptionsListCount
            }
            addNewPriority={props.addNewPriority}
            unSelectedListCount={props.unSelectedListCount}
            updateGroup={props.updateGroup}
            dealerGroups={props.dealerGroups}
          />

          <DealerExclusions
            tdBucket={props.tdBucket}
            selectedOption={selectedOption[0]}
            timingsChanged={props.timingsChanged}
            axedOnOtherSideAlwaysHandler={
              props.axedOnOtherSideAlwaysHandler
            }
            passedInLastChange={props.passedInLastChange}
            copySettingsFromPreviousBucket={props.copySettingsFromPreviousBucket}
            copySettingFromPreviouseBucketEnabled={props.copySettingFromPreviouseBucketEnabled}
            tdInventorySizeZeroMySideHandler={props.tdInventorySizeZeroMySideHandler}
            openTradingExclusionHandler={props.openTradingExclusionHandler}
          />
        </div>
          );
        };

export default topDealer;


topDealer.propTypes = {
  dealerPriorityListSelectOptions: PropTypes.array,
  topDealersQuantityArray: PropTypes.array,
  tdBucket: PropTypes.number.isRequired,
  selectableOptions: PropTypes.array,
  removeSelectionPriorityRow: PropTypes.func,
  selectionPriorityValueChange: PropTypes.func,
  selectionPriorityQuantityChange: PropTypes.func,
  setDragData: PropTypes.func,
  drop: PropTypes.func.isRequired,
  dragOver: PropTypes.func.isRequired,
  addNewPriority: PropTypes.func.isRequired,
  dealerPriorityListCount: PropTypes.number,
  dealerSelectionsOptionsListCount: PropTypes.number,
  unSelectedListCount: PropTypes.number
};
