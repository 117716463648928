import React, { Component } from "react";
import PropTypes from "prop-types";
import MarketSegments from "../../components/market-segments/market-segments";
import IsinIssuersIncludes from "../../components/isins-issuers/includes/includes";
import IsinIssuersExcludes from "../../components/isins-issuers/excludes/excludes";
import DealerExclusions from "../../components/dealer-exclusions/dealer-exclusions";
import marketSegments from "../../components/dealer-priority-container/dealer-priority-container";
import HistoricPerfomance from "../../components/historic-performance/historic-performance";
import GeneralPreferences from "../../components/general-preferences/general-preferences";
import GoToAll from "../../components/go-to-all/go-to-all";
import ErrorPage from "../../components/error-page/error-page";
import TopDealer from "../../components/top-dealer/top-dealer";
import { connect } from "react-redux";
import { orderBy } from "lodash";
import * as actionTypes from "../../store/actions/actions";
import * as actionCreators from "../../store/action-creators/action-creators";
import { numberWithCommas } from "../../utils/utils";
import { Helmet } from "react-helmet";
import axios from "axios";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "react-loading-overlay";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";

export class SmartSelect extends Component {
  state = { stylePath: "", spinnerActive: false };

  componentWillMount() {
    let stylePath = new URL(window.location.href).searchParams.get("source");
    if (stylePath) {
      if (stylePath.toLowerCase() === "ma") {
        this.setState({ stylePath: "maStyle_v4.css" });
      } else {
        this.setState({ stylePath: "emsStyle_v4.css" });
      }
    } else {
      this.setState({ stylePath: "emsStyle_v4.css" });
    }
  }

  componentDidMount() {
    this.props.setEnvironment();
  }

  getDealerPriorityListHandler = dealerPriorityListData => {
    let selectionListArray = dealerPriorityListData.filter(
      selectionListItem => {
        return selectionListItem.selected;
      }
    );
    let tdBuckets = selectionListArray[0].tdBuckets.filter(
      item => {
        return parseInt(item.type) === this.props.tdBucket;
      }
    );

    return orderBy(
      tdBuckets[0].dealerSelectionPriorityList,
      "priority",
      "asc"
    );
  };

  getSelectedSegmentValuesHandler = (dealerPriorityListData, key) => {
    let selectionListArray = [...dealerPriorityListData].filter(
      selectionListItem => {
        return selectionListItem.selected;
      }
    );
    return selectionListArray[0][key];
  };

  getSelectedBucketSizeValue = (dealerPriorityListData, key) => {
	    let selectionListArray = [...dealerPriorityListData].filter(
	      selectionListItem => {
	        return selectionListItem.selected;
	      }
	    );
	    return selectionListArray[0][key];
	  };

  getBoundSizeDiff = (getBucketOptions, tdBucketIndex) => {
       let selectedBucket = getBucketOptions.filter(
       bucketData => {
               return parseInt(bucketData.type) === tdBucketIndex;
             });
       let nextBucket = getBucketOptions.filter(
          bucketData => {
               return parseInt(bucketData.type) === tdBucketIndex+1;
       });

       let smartSelectType = this.getSelectedSegmentValuesHandler(
           this.props.dealerPriorityList,
           "smartSelectType"
       );
       if(tdBucketIndex === 1 && selectedBucket[0].selectAllSize === null ||
            selectedBucket[0].selectAllSize === ""){
              return "-";
       }
	  if (tdBucketIndex != 1 && (!selectedBucket[0].active ||
	        selectedBucket[0].selectAllSize === null ||
            selectedBucket[0].selectAllSize === "")){
		  return "-";
	  } else if(tdBucketIndex === 4 || !nextBucket[0].active || nextBucket[0].selectAllSize === null ||
                nextBucket[0].selectAllSize === ""){
		  return tdBucketIndex === 1 && smartSelectType.toLowerCase() !== "top dealers"? ((parseInt(selectedBucket[0].selectAllSize) + 1)+"  +") : (selectedBucket[0].selectAllSize +"  +");
	  } else {
		  return tdBucketIndex === 1 && smartSelectType.toLowerCase() !== "top dealers" ? ((parseInt(selectedBucket[0].selectAllSize) + 1)+"  -  " +this.findRange(nextBucket[0].selectAllSize, 1)):
		    (selectedBucket[0].selectAllSize+"  -  "+ this.findRange(nextBucket[0].selectAllSize, 1));
	  }
	  
  };

    checkBoundSizeDiffError = (getBucketOptions, tdBucketIndex) => {
    let isError = "";
       let selectedBucket = getBucketOptions.filter(
           bucketData => {
                return parseInt(bucketData.type) === tdBucketIndex;
           });
       let previousBucket = getBucketOptions.filter(
          bucketData => {
                return parseInt(bucketData.type) === tdBucketIndex-1;
          });
      if ((tdBucketIndex === 1 && selectedBucket[0].selectAllSize === "") || (tdBucketIndex != 1 &&
            selectedBucket[0].active && (
            selectedBucket[0].selectAllSize !== null ||
            selectedBucket[0].selectAllSize !== "" ||
            previousBucket[0].selectAllSize !== null ||
            previousBucket[0].selectAllSize !== "") &&
            (selectedBucket[0].selectAllSize - (tdBucketIndex===2?2:1)) <= previousBucket[0].selectAllSize)){
      	        isError = "error";
      }
      if((tdBucketIndex === 1 || (tdBucketIndex !== 1 && selectedBucket[0].active)) && selectedBucket[0].maxNoDealers === ""){
        isError = "error";
      }
      if((tdBucketIndex === 1 || (tdBucketIndex !== 1 && selectedBucket[0].active)) && selectedBucket[0].dealerPassedThreshold && selectedBucket[0].dealerPassedThresholdTime === ""){
        isError = "error";
      }

      if((tdBucketIndex === 1 || (tdBucketIndex !== 1 && selectedBucket[0].active)) && selectedBucket[0].dealerSelectionPriorityList.length){
        selectedBucket[0].dealerSelectionPriorityList.map(priority => {
            if(priority.value === "" || priority.quantity === "" || (priority.groups && priority.groups.length && !priority.group)){
            isError = "error";
        }
      });
      }

      return isError;
    };
  findRange = (bound, diffValue) => {
    return parseInt(bound) - diffValue;
  }

  getHistoricalOptions = dealerPriorityListData => {
    let historicOptions;
    for (let i = 0; i < dealerPriorityListData.length; i++) {
      if (dealerPriorityListData[i].selected) {
        historicOptions = dealerPriorityListData[i].liquidity;
        return historicOptions;
      }
    }
  };

   getBucketOptions = dealerPriorityListData => {
     let bucketOptions;
     for (let i = 0; i < dealerPriorityListData.length; i++) {
         if (dealerPriorityListData[i].selected) {
            bucketOptions = dealerPriorityListData[i].tdBuckets;
            return bucketOptions;
         }
     }
  };

  getSelectAllSize = (bucketDatas, tdBucket) => {
    let selectionListArray = bucketDatas.filter(
        selectionListItem => {
            return selectionListItem.selected;
        }
    );

    let selectAllSize = selectionListArray[0].tdBuckets.filter(
      item => {
            return parseInt(item.type) === tdBucket;
      }
    );

    return selectAllSize[0].selectAllSize;
  }
  checkDealerPrioritiesCompletedtHandler = dealerPriorityListData => {
    let selectionListArray = dealerPriorityListData.filter( data => {
        return data.selected;
      }
    );

    let tdBuckets = selectionListArray[0].tdBuckets.filter(
      item => {
        return parseInt(item.type) === this.props.tdBucket;
      }
    );
    let unSelectedLitsArray = tdBuckets[0].dealerSelectionPriorityList !== null ? tdBuckets[0].dealerSelectionPriorityList.filter(
      item => {
        return item.value === "";
      }
    ):[];

    return unSelectedLitsArray.length;
  };

  saveSelectionHandler = currentState => {
    if (!currentState) return;
    this.setState({ ...this.state, spinnerActive: true });
    let postUrl = `${window.location.protocol}//${
      window.location.host
    }/userSmartSelect.html`;

    axios
      .post(postUrl, currentState)
      .then(response => {
        this.setState({ ...this.state, spinnerActive: false });
        if (response.status === 200) {
          this.props.saveSelection(true);
        } else {
          console.log(`Saved: ${response.status}`);
          this.props.saveSelection(false);
        }
      })
      .catch(error => {
        this.setState({ ...this.state, spinnerActive: false });
        console.log(`error completing save action`);
        alert(
          "Error completing save action: Please contact your IT Administrator"
        );
      });
  };

  render() {
    const topDealersQuantityArray = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      "All"
    ];

    return this.props.isDataError ? (
      <div className="container mt-5 parent">
        <ErrorPage
          dataErrorCode={this.props.dataErrorCode}
          dataErrorMessage={this.props.dataErrorMessage}
        />
      </div>
    ) : (
      <LoadingOverlay
        active={this.state.spinnerActive}
        spinner
        text="Saving your preferences.  Please wait..."
        className="spinner-class"
      >
        <div className="container-item parent">
          <Helmet>
            <meta charSet="utf-8" />
            <link
              rel="stylesheet"
              type="text/css"
              href={this.state.stylePath}
            />
          </Helmet>
          <div className="row parent full-width">
            <Modal
              isOpen={this.props.modalIsOpen}
              onRequestClose={this.closeModal}
              className="modalStyles"
              shouldCloseOnOverlayClick={true}
              overlayClassName="modal-overlay"
            >
              <div className="col-xs-12 col-md-12 modal-header bottom-rule">
                <FontAwesomeIcon
                  icon={faCopy}
                  size="lg"
                  className="sides light"
                />
                <label>
                  {" "}
                  {`Copy settings from ${this.props.selectedSegment} to:`}
                </label>
              </div>
              <div className="col-xs-12 col-md-12 bottom-rule">
                {this.props.marketSegmentsList.map(item => {
                  return item.segment !== this.props.selectedSegment ? (
                    <div key={item.segment}>
                      <label className="sides shift-up">{item.segment}</label>
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={evt =>
                          this.props.setCopyCheckboxSettingsState(
                            evt.target.checked,
                            item.segment
                          )
                        }
                      />
                    </div>
                  ) : null;
                })}
              </div>
              <div className="col xs-12 col-md-12 flex-item pb-15">
                <button
                  type="button"
                  disabled={!this.props.copyToList.length}
                  className="btn btn-primary margin-sides"
                  onClick={this.props.copyItemsTo}
                >
                  OK
                </button>
                <button
                  type="button"
                  className="btn btn-danger margin-sides"
                  onClick={this.props.closeModal}
                >
                  Cancel
                </button>
              </div>
            </Modal>
            <Modal
              isOpen={this.props.savedAlertOpen}
              onRequestClose={this.closeSavedModal}
              className="modalStyles"
              shouldCloseOnOverlayClick={true}
              overlayClassName="modal-overlay"
            >
              {this.props.isSavedSuccess ? (
                <div className="col-xs-12 col-md-12 modal-header-success bottom-rule">
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="lg"
                    className="sides light"
                  />
                  <label> {`Success`}</label>
                </div>
              ) : (
                <div className="col-xs-12 col-md-12 modal-header bottom-rule">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    size="lg"
                    className="sides light"
                  />
                  <label> {`Error`}</label>
                </div>
              )}
              <div className="col-xs-12 col-md-12 bottom-rule">
                {this.props.isSavedSuccess ? (
                  <p className="mtb-30">
                    <label className="font-norm">Preferences have been successfully saved</label>
                  </p>
                ) : (
                  <p className="mtb-30">
                    <label className="font-norm">
                      {" "}
                      An error was encountered when attempting to save your
                      configuration. Please contact your IT Administrator for
                      help with resolving the issue
                    </label>
                  </p>
                )}
              </div>
              <div className="col xs-12 col-md-12 flex-item pb-15">
                <button
                  type="button"
                  className="btn btn-primary margin-sides"
                  onClick={this.props.closeSavedModal}
                >
                  OK
                </button>
              </div>
            </Modal>
            <div className="col-xs-2 col-md-2 background-primary ptb-15">
              <MarketSegments
                segments={this.props.marketSegmentsList}
                segmentClickChanged={this.props.segmentClickChanged}
                selectedSegment={this.props.selectedSegment}
              />
            </div>
            <div className="col-xs-10 col-md-10 pt-15 background-grey">
              <GeneralPreferences
                smartSelectType={this.getSelectedSegmentValuesHandler(
                  this.props.dealerPriorityList,
                  "smartSelectType"
                )}
                bucketOptions={this.getBucketOptions(this.props.dealerPriorityList)}
                goToChanged={this.props.goToChanged}
              	bucketOneSize={this.getSelectedSegmentValuesHandler(
                  this.props.dealerPriorityList,
                  "selectAllSize"
                )}
              	selectAllSizeChanged={this.props.selectAllSizeChanged}
              	bucketSizeReset={this.props.bucketSizeReset}
              />
              <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={["Go To All", "Go To All & Top Dealers"]}
              >
                <AccordionItem uuid={"Go To All"}>
                  <AccordionItemHeading
                    className={
                      this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "smartSelectType"
                      ).toLowerCase() === "top dealers"
                        ? "mid-opacity"
                        : null
                    }
                  >
                    <AccordionItemButton>
                      Go to All
                      {this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "smartSelectType"
                      ).toLowerCase() === "go to all & top dealers" ? (
                        <span className="flex-text">
                          Go To All when inquiry size is less than or equal to
                          (000s){" "}
                          {numberWithCommas(
                            this.getSelectAllSize(this.props.dealerPriorityList, 1)
                          )}                          
                        </span>
                      ) : null}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <GoToAll
                      gtaResponseRateThreshold={this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "gtaResponseRateThreshold"
                      )}
                      gtaResponseRateActive={this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "gtaResponseRateActive"
                      )}
                      gtaAxedOtherSideOnly={this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "gtaAxedOtherSideOnly"
                      )}
                      gtaNeverExclAxedYourSide={this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "gtaNeverExclAxedYourSide"
                      )}
                      gtaPassedThreshold={this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "gtaPassedThreshold"
                      )}
                      gtaPassedActive={this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "gtaPassedActive"
                      )}
                      gtaTimingsChanged={this.props.gtaTimingsChanged}
                      gtaAxedOnOtherSideAlwaysHandler={
                        this.props.gtaAxedOnOtherSideAlwaysHandler
                      }
                      gtaAxedOnOtherSideNeverHandler={
                        this.props.gtaAxedOnOtherSideNeverHandler
                      }
                      gtaPassedInLastChange={this.props.gtaPassedInLastChange}
                      gtaInventorySizeZeroMySideHandler={
                        this.props.gtaInventorySizeZeroMySideHandler
                      }
                      gtaInventorySizeZeroMySide={this.getSelectedSegmentValuesHandler(
                          this.props.dealerPriorityList,
                          "gtaInventorySizeZeroMySide"
                      )}
                      responseRateValueChanged={
                        this.props.responseRateValueChanged
                      }
                      responseRateSlideChanged={
                        this.props.responseRateSlideChanged
                      }
                      smartSelectType={this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "smartSelectType"
                      )}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid={"Go To All & Top Dealers"}>
                  <AccordionItemHeading
                    className={
                      this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "smartSelectType"
                      ).toLowerCase() === "go to all"
                        ? "mid-opacity"
                        : null
                    }
                  >
                    <AccordionItemButton>
                      Top Dealers{" "}
                      {this.getSelectedSegmentValuesHandler(
                          this.props.dealerPriorityList,
                          "smartSelectType"
                        ).toLowerCase() !== "go to all" ? (
                        	<span className="flex-bucket">
                              <div className="bucket">
                                <ul className="nav nav-pills">
                                   <li
                                   	className={
                                	`${parseInt("1") === this.props.tdBucket
                                   		? "bucket active"
                                   		: "bucket"}
                                   	 ${this.checkBoundSizeDiffError(
                                            this.getBucketOptions(this.props.dealerPriorityList),
                                            1
                                        )
                                      }`
                                   	}
                                   	onClick={(event) => this.props.bucketOptionsChanged(event,1)}
                                   >
                                     <a className="bucketa">{this.getBoundSizeDiff(
                                                this.getBucketOptions(this.props.dealerPriorityList),
                                                1
                                               )}</a>
                                   </li>
                                   <li
                                   	className={
                                		`${parseInt("2") === this.props.tdBucket
                                   		? "bucket active"
                                   		: "bucket"}
                                   		${this.checkBoundSizeDiffError(
                                   		    this.getBucketOptions(this.props.dealerPriorityList),
                                            2
                                   		)
                                        }`
                                   	}
                                	onClick={(event) => this.props.bucketOptionsChanged(event,2)}
                                   >
                                   	 <a className="bucketa">{this.getBoundSizeDiff(
                                             this.getBucketOptions(this.props.dealerPriorityList),
                                             2
                                          )}</a>
                                   </li>
                                   <li
                                   	className={
                                		`${parseInt("3") === this.props.tdBucket
                                   		? "bucket active"
                                	    : "bucket"}
                                	    ${this.checkBoundSizeDiffError(
                                            this.getBucketOptions(this.props.dealerPriorityList),
                                            3
                                        )
                                        }`
                                    }
                                	onClick={(event) => this.props.bucketOptionsChanged(event,3)}
                                   >
                                     <a className="bucketa">{this.getBoundSizeDiff(
                                             this.getBucketOptions(this.props.dealerPriorityList),
                                             3)}
                                     </a>
                                   </li>
                                   <li
                                   	className={
                                		`${parseInt("4") === this.props.tdBucket
                                   		? "bucket active"
                                   		: "bucket"}
                                   		${this.checkBoundSizeDiffError(
                                            this.getBucketOptions(this.props.dealerPriorityList),
                                            4
                                        )
                                   		}`
                                   	}
                                	onClick={(event) => this.props.bucketOptionsChanged(event,4)}
                                   >
                                     <a className="bucketa">
                                     { this.getBoundSizeDiff(
                                            this.getBucketOptions(this.props.dealerPriorityList),
                                            4)
                                     }</a>
                                   </li>
                                </ul>
                              </div>	
                              </span>
                        ) : null}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <TopDealer
                      tdBucket={this.props.tdBucket}
                      bucketOptions={this.getBucketOptions(this.props.dealerPriorityList)}
                      smartSelectType = {this.getSelectedSegmentValuesHandler(
                           this.props.dealerPriorityList,
                           "smartSelectType"
                         ).toLowerCase()}
                      includeTopDealers = {this.props.includeTopDealers}
                      topDealersQuantityArray = {topDealersQuantityArray}
                      dealerPriorityList={this.getDealerPriorityListHandler(
                        this.props.dealerPriorityList
                      )}
                      selectableOptions={this.props.selectableOptions}
                      removeSelectionPriorityRow={
                        this.props.removeSelectionPriorityRow
                      }
                      selectionPriorityValueChange={this.props.selectionPriorityValueChange}
                      selectionPriorityQuantityChange={
                        this.props.selectionPriorityQuantityChange
                      }
                      bucketSizeChanged={this.props.selectAllSizeChanged}
                      bucketEnableSlideChanged={this.props.bucketEnableSlideChanged}
                      axedOnOtherSideAlwaysHandler={this.props.axedOnOtherSideAlwaysHandler}
                      copySettingsFromPreviousBucket={this.props.copySettingsFromPreviousBucket}
                      tdInventorySizeZeroMySideHandler={
                        this.props.tdInventorySizeZeroMySideHandler
                      }
                      openTradingExclusionHandler={this.props.openTradingExclusionHandler}
                      passedInLastChange={this.props.passedInLastChange}
                      bucketSizeReset={this.props.bucketSizeReset}
                      timingsChanged={this.props.timingsChanged}
                      dragOver={this.props.dragOver}
                      drop={this.props.drop}
                      setDragData={this.props.setDragData}
                      dragEnd={this.props.dragEnd}
                      dealerSelectionsOptionsListCount={
                        this.props.dealerSelectionsOptionsListCount
                      }
                      addNewPriority={this.props.addNewPriority}
                      unSelectedListCount={this.checkDealerPrioritiesCompletedtHandler(
                        this.props.dealerPriorityList
                      )}
                      updateGroup={this.props.updateGroup}
                      dealerGroups={this.props.dealerGroups}
                   />
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid={"historicalPerformance"}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Historical Dealer Performance Settings
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="row">
                      <HistoricPerfomance
                        historicPerformanceOption={
                          this.props.historicPerformanceOption
                        }
                        historicalOptions={this.getHistoricalOptions(
                          this.props.dealerPriorityList
                        )}
                        highhistoricalChange={this.props.highhistoricalChanged}
                        historicOptionsChange={
                          this.props.historicOptionsChanged
                        }
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid={"isin"}>
                  <AccordionItemHeading
                    className={
                      this.getSelectedSegmentValuesHandler(
                        this.props.dealerPriorityList,
                        "smartSelectType"
                      ).toLowerCase() === "go to all"
                        ? "mid-opacity"
                        : null
                    }
                  >
                    <AccordionItemButton>
                      ISIN / Ticker Include / Exclude Rules
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      className={`row ${
                        this.getSelectedSegmentValuesHandler(
                          this.props.dealerPriorityList,
                          "smartSelectType"
                        ).toLowerCase() === "go to all"
                          ? "overlay"
                          : ""
                      }`}
                    >
                      <IsinIssuersIncludes
                        type="Includes"
                        isinIssuersChangeIncludes={
                          this.props.isinIssuersChangeIncludes
                        }
                        isinDealersChangeIncludes={
                          this.props.isinDealersChangeIncludes
                        }
                        isinIssuersTextIncludes={
                          this.props.isinIssuersTextIncludes
                        }
                        isinDealersTextIncludes={
                          this.props.isinDealersTextIncludes
                        }
                      />
                      <IsinIssuersExcludes
                        type="Excludes"
                        isinIssuersChangeExcludes={
                          this.props.isinIssuersChangeExcludes
                        }
                        isinDealersChangeExcludes={
                          this.props.isinDealersChangeExcludes
                        }
                        isinIssuersTextExcludes={
                          this.props.isinIssuersTextExcludes
                        }
                        isinDealersTextExcludes={
                          this.props.isinDealersTextExcludes
                        }
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <div className="col xs-12 col-md-12 flex-item pb-15">
                <button
                  type="button"
                  className="btn btn-default margin-sides"
                  onClick={this.props.openModal}
                >
                  Copy settings to
                </button>
                <button
                  type="button"
                  className="btn btn-primary margin-sides"
                  disabled={this.props.isError}
                  onClick={() =>
                    this.saveSelectionHandler(this.props.smartSelectData)
                  }
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-danger margin-sides"
                  onClick={this.props.resetCurrentSegment}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  if(state.smartSelectIdentity != null){
    return {
      modalIsOpen: state.modalIsOpen,
      savedAlertOpen: state.savedAlertOpen,
      isSavedSuccess: state.isSavedSuccess,
      marketSegmentsList: state.options ? state.options.marketSegmentsList : null,
      historicPerformanceOption: state.historicPerformanceOption,
      dealerPriorityList: state.smartSelectData,
      selectableOptions: state.selectableOptions,
      dealerSelectionsOptionsListCount: state.options
        ? state.options.dealerSelectionsOptionsList.length
        : null,
      isinIssuersTextIncludes: state.isinsIssuersIncludes,
      isinDealersTextIncludes: state.dlrsIncludes,
      isinIssuersTextExcludes: state.isinsIssuersExcludes,
      isinDealersTextExcludes: state.dlrsExcludes,
      smartSelectType: state.smartSelectType,
      selectedSegment: state.selectedSegment,
      isError: state.error,
      smartSelectData: state,
      isDataError: state.isDataError,
      dataErrorCode: state.dataErrorCode,
      dataErrorMessage: state.dataErrorMessage,
      copyToList: state.copyToList ? state.copyToList : [],
      spinnerActive: state.spinnerActive,
      dealerGroups: state.options ? state.options.dealerGroups: null,
      tdBucket: state.tdBucket,
      copySettingFromPreviouseBucketEnabled2: state.copySettingFromPreviouseBucketEnabled2,
      copySettingFromPreviouseBucketEnabled3: state.copySettingFromPreviouseBucketEnabled3,
      copySettingFromPreviouseBucketEnabled4: state.copySettingFromPreviouseBucketEnabled4
    };
  } else{
    return {
      isDataError: true,
      dataErrorMessage: "There was an error loading data. Please try again later"
    };
  }
  
};

const mapDispatchToProps = dispatch => {
  return {
    setEnvironment: () => dispatch(actionCreators.setEnvironment()),
    setHasSelection: () =>
      dispatch({
        type: actionTypes.SET_HAS_SELECTION
      }),
    segmentClickChanged: value =>
      dispatch(actionCreators.segmentClickChanged(value)),
    selectAllSizeChanged: (value, tdBucket) =>{
      dispatch({
        type: actionTypes.SELECT_ALL_SIZE_CHANGE,
        payload: { value, tdBucket }
      });
      dispatch({
        type: actionTypes.CHECK_ERROR
      });
    },

    dragOver: (evt, tdBucket) =>
      dispatch({
        type: actionTypes.DRAG_OVER_PRIORITY,
        payload: { value: evt, tdBucket }
      }),
    drop: (evt, changeIndex, tdBucket) =>
      dispatch(actionCreators.dealerPriorityDrop(evt, changeIndex, tdBucket)),
    setDragData: (evt, changeIndex, tdBucket) =>
      dispatch({
        type: actionTypes.SET_DRAG_DATA,
        payload: { value: evt, changeIndex: changeIndex, tdBucket: tdBucket }
      }),
    dragEnd: (evt, tdBucket) =>
      dispatch({
        type: actionTypes.DRAG_END_PRIORITY,
        payload: { value: evt, tdBucket }
      }),
    historicOptionsChanged: value =>
      dispatch({
        type: actionTypes.HISTORIC_OPTION_CHANGE,
        payload: { value }
      }),
    bucketOptionsChanged: (event,value) =>
      {
      dispatch({
        type: actionTypes.BUCKET_OPTION_CHANGE,
        payload: { value }
      })
      event.stopPropagation();
      },
    bucketSizeReset: () => 
      dispatch(actionCreators.bucketSizeReset()),
    addNewPriority: (tdBucket) => dispatch(actionCreators.addNewPriority(tdBucket)),
    removeSelectionPriorityRow: (priority,tdBucket) =>
      dispatch(actionCreators.removeSelectionPriorityRow(priority,tdBucket)),
    selectionPriorityValueChange: (value, changeIndex, tdBucket) =>
      dispatch(actionCreators.dealerPriorityChange(value, changeIndex, tdBucket)),
    selectionPriorityQuantityChange: (value, changeIndex, tdBucket) =>
      dispatch(
        actionCreators.selectionPriorityQuantityChange(value, changeIndex, tdBucket)
      ),
    highhistoricalChanged: (value, name) =>
      dispatch({
        type: actionTypes.HISTORICAL_CHANGE,
        payload: { value, name }
      }),
    updateGroup: (group, groupParent, changeIndex, tdBucket) =>
      dispatch(actionCreators.updateGroup(group, groupParent, changeIndex, tdBucket)),
    includeTopDealers: (value, tdBucket) =>
      dispatch(actionCreators.includeTopDealers(value,tdBucket)),
    gtaAxedOnOtherSideAlwaysHandler: value =>
      dispatch(actionCreators.gtaAxedOtherSideAlwaysChanged(value)),
    axedOnOtherSideAlwaysHandler: (value, tdBucket) =>
      dispatch(actionCreators.axedOtherSideAlwaysChanged(value, tdBucket)),
    gtaInventorySizeZeroMySideHandler: value =>
          dispatch(actionCreators.gtaInventorySizeZeroMySideChanged(value)),
    tdInventorySizeZeroMySideHandler: (value, tdBucket) =>
          dispatch(actionCreators.tdInventorySizeZeroMySideChanged(value, tdBucket)),
    openTradingExclusionHandler: (value, tdBucket) =>
        dispatch(actionCreators.openTradingExclusionChanged(value, tdBucket)),
    gtaAxedOnOtherSideNeverHandler: value =>
      dispatch(actionCreators.gtaAxedOtherSideNeverChanged(value)),
    passedInLastChange: (value, tdBucket) =>
      dispatch(actionCreators.passedInLastChange(value,tdBucket)),
    gtaPassedInLastChange: value =>
      dispatch(actionCreators.gtaPassedInLastChange(value)),
    gtaTimingsChanged: value =>
      dispatch(actionCreators.gtaTimingsChanged(value)),
    timingsChanged: (value, tdBucket) => dispatch(actionCreators.timingsChanged(value, tdBucket)),
    responseRateValueChanged: value =>
      dispatch(actionCreators.responseRateValueChanged(value)),
    responseRateSlideChanged: value =>
      dispatch(actionCreators.responseRateSlideChanged(value)),
    bucketEnableSlideChanged: (value, tdBucket) =>
      dispatch(actionCreators.bucketEnableSlideChanged(value,tdBucket)),
    copySettingsFromPreviousBucket: (value, tdBucket) =>{
        dispatch({
          type: actionTypes.COPY_SETTINGS_FROM_PREVIOUS_BUCKET,
          payload: { value, tdBucket }
        });
        dispatch({
          type: actionTypes.SET_HAS_SELECTION
        });
        },
    goToChanged: value =>{
      dispatch({
        type: actionTypes.GO_TO_CHANGED,
        payload: { value }
      });
      dispatch({
        type: actionTypes.CHECK_ERROR
      });
    },
    isinIssuersChangeIncludes: value =>
      dispatch(actionCreators.isinIssuersChangeIncludes(value)),
    isinIssuersChangeExcludes: value =>
      dispatch(actionCreators.isinIssuersChangeExcludes(value)),
    isinDealersChangeExcludes: value =>
      dispatch(actionCreators.isinDealersChangeExcludes(value)),
    isinDealersChangeIncludes: value =>
      dispatch(actionCreators.isinDealersChangeIncludes(value)),
    resetCurrentSegment: () => dispatch(actionCreators.resetCurrentSegment()),
    setCopyCheckboxSettingsState: (checked, segment) =>
      dispatch(actionCreators.setCopyCheckboxSettingsState(checked, segment)),
    copyItemsTo: () => dispatch(actionCreators.copyItemsTo()),
    openModal: () =>
      dispatch({
        type: actionTypes.OPEN_MODAL
      }),
    closeModal: () =>
      dispatch({
        type: actionTypes.CLOSE_MODAL
      }),
    closeSavedModal: () =>
      dispatch({
        type: actionTypes.CLOSE_SAVED_MODAL
      }),
    saveSelection: saveState =>
      dispatch(actionCreators.saveSelection(saveState))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartSelect);

SmartSelect.propTypes = {
  marketSegmentsList: PropTypes.array.isRequired,
  historicPerformanceOption: PropTypes.string.isRequired,
  dealerPriorityList: PropTypes.array.isRequired,
  selectableOptions: PropTypes.array.isRequired,
  dealerSelectionsOptionsListCount: PropTypes.number.isRequired,
  isinIssuersTextIncludes: PropTypes.string,
  isinDealersTextIncludes: PropTypes.string,
  isinIssuersTextExcludes: PropTypes.string,
  isinDealersTextExcludes: PropTypes.string,
  smartSelectType: PropTypes.string,
  selectedSegment: PropTypes.string,
  isError: PropTypes.bool,
  smartSelectData: PropTypes.object.isRequired,
  setEnvironment: PropTypes.func.isRequired,
  setHasSelection: PropTypes.func.isRequired,
  segmentClickChanged: PropTypes.func.isRequired,
  dragOver: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired,
  selectAllSizeChanged: PropTypes.func.isRequired,
  setDragData: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
  historicOptionsChanged: PropTypes.func.isRequired,
  addNewPriority: PropTypes.func.isRequired,
  removeSelectionPriorityRow: PropTypes.func.isRequired,
  selectionPriorityValueChange: PropTypes.func.isRequired,
  selectionPriorityQuantityChange: PropTypes.func.isRequired,
  highhistoricalChanged: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  includeTopDealers: PropTypes.func.isRequired,
  gtaAxedOnOtherSideAlwaysHandler: PropTypes.func.isRequired,
  gtaInventorySizeZeroMySideHandler: PropTypes.func.isRequired,
  tdInventorySizeZeroMySideHandler: PropTypes.func.isRequired,
  openTradingExclusionHandler: PropTypes.func.isRequired,
  axedOnOtherSideAlwaysHandler: PropTypes.func.isRequired,
  gtaAxedOnOtherSideNeverHandler: PropTypes.func.isRequired,
  passedInLastChange: PropTypes.func.isRequired,
  gtaPassedInLastChange: PropTypes.func.isRequired,
  timingsChanged: PropTypes.func.isRequired,
  responseRateValueChanged: PropTypes.func.isRequired,
  responseRateSlideChanged: PropTypes.func.isRequired,
  bucketEnableSlideChanged: PropTypes.func.isRequired,
  goToChanged: PropTypes.func.isRequired,
  isinIssuersChangeIncludes: PropTypes.func.isRequired,
  isinDealersChangeIncludes: PropTypes.func.isRequired,
  isinIssuersChangeExcludes: PropTypes.func.isRequired,
  isinDealersChangeExcludes: PropTypes.func.isRequired,
  resetCurrentSegment: PropTypes.func.isRequired,
  saveSelection: PropTypes.func.isRequired,
  bucketSizeReset: PropTypes.func.isRequired 
};
