import React from "react";
import PropTypes from "prop-types";
import "./dealer-exclusions.css";
import Timings from "../timings/timings";
import Switch from "react-switch";

const dealerExclusions = props => {
  const timingsArray = [15, 30, 45, 60, 180, 360, 720, 1440];
  return (
    <div className="col-xs-4 col-md-4" disabled={props.tdBucket != 1 && !props.selectedOption.active}>
      <div className="row">
        {props.tdBucket !== 1 ?
            <div className="col-xs-12 col-md-12 mb-15">
              <label className="font-norm">Copy settings from previous size bucket</label>
              <Switch
                  className={"margin-sides"}
                  height={15}
                  width={30}
                  onChange={evt => props.copySettingsFromPreviousBucket(evt, props.tdBucket)}
                  checked={props.selectedOption.copySettingFromPreviouseBucketEnabled}
                  onColor="#337ab7"
                  offColor="#a0a3a8"
              />
            </div> : ""}
        <div className="col-xs-12 col-md-12 mb-5">
          <label> Always exclude dealers if:</label>
        </div>
        <div className="col-xs-12 col-md-12 mb-5">
          <label className="font-norm">They passed in the last</label>
          <Switch
              className={"margin-sides"}
              height={15}
              width={30}
              onChange={evt => props.passedInLastChange(evt, props.tdBucket)}
              checked={props.selectedOption.dealerPassedThreshold}
              onColor="#337ab7"
              offColor="#a0a3a8"
          />
          <Timings
              timingsArray={timingsArray}
              dlrsLastPassed={props.selectedOption.dealerPassedThreshold}
              dealerPassedThresholdTime={props.selectedOption.dealerPassedThresholdTime}
              timingsChanged={props.timingsChanged}
              tdBucket={props.tdBucket}
          />
        </div>
        <div className="col-xs-12 col-md-12 mb-5">
          <label className="font-norm">They're Axed on the other side</label>
          <Switch
              className={"margin-sides"}
              height={15}
              width={30}
              onChange={evt => props.axedOnOtherSideAlwaysHandler(evt, props.tdBucket)}
              checked={props.selectedOption.dlrsAxedOtherSide}
              onColor="#337ab7"
              offColor="#a0a3a8"
          />
        </div>
        <div className="col-xs-12 col-md-12 mb-5">
          <label className="font-norm"> Inventory size is zero on my side</label>
          <Switch
              className={"margin-sides"}
              height={15}
              width={30}
              onChange={evt => props.tdInventorySizeZeroMySideHandler(evt, props.tdBucket)}
              checked={props.selectedOption.inventorySizeZeroMySide}
              onColor="#337ab7"
              offColor="#a0a3a8"
          />
        </div>
        <div className="col-xs-12 col-md-12 mb-5">
          <label className="font-norm">Exclude Open Trading</label>
          <Switch
              className={"margin-sides"}
              height={15}
              width={30}
              onChange={evt => props.openTradingExclusionHandler(evt, props.tdBucket)}
              checked={props.selectedOption.excludeOpenTrading}
              onColor="#337ab7"
              offColor="#a0a3a8"
          />
        </div>
      </div>
    </div>
  );
};

export default dealerExclusions;

dealerExclusions.propTypes = {
  onChange: PropTypes.bool,
  dlrsLastPassed: PropTypes.bool,
  dealerPassedThresholdActive: PropTypes.bool,
  dealerPassedThresholdTime: PropTypes.number,
  timingsArray: PropTypes.array,
  timingsChanged: PropTypes.func.isRequired,
  axedOnOtherSideAlwaysHandler: PropTypes.func.isRequired,
  passedInLastChange: PropTypes.func.isRequired,
  tdBucket: PropTypes.number
};
